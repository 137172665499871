import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, carousel2, dataTerminal, hero, pulQutulari, blogs1, blogs2, blogs3, project1, project2, project3, project4, project5, project6, rightArrow, rightUp, arrow, service1, service2, service3, service4 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const services = [
    { image: service1, title: "Veb-sayt hazırlanması", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'vebsayt' },
    { image: service2, title: "Mobil tətbiqlərin hazırlanması", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'mobil' },
    { image: service3, title: "Təlimçi bazası", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'telimci' },
    { image: service4, title: "SMM Xidmətləri", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'smm' },
  ];


const ServicesShort = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <div className='max-w-containerSm md:max-w-container mx-auto md:mt-[80px] mt-[48px]'>
    <p className='text-[20px] md:text-[40px] font-semibold text-center'>Xidmətlər</p>
    <div className='md:mt-[44px] mt-[36px] grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8'>
   {services.map((services) => (
                     <div className='px-5 py-5 md:py-6 rounded-[20px] bg-white'>
                     <img className='md:h-[64px] h-[36px] md:w-[64px] h-[36px]' src={services.image}></img>
                     <div className='md:h-[124px] flex flex-col justify-between mt-[20px] md:mt-[32px]'>
                     <p className='text-[16px] md:text-[20px] font-medium'>{services.title}</p>
                     <p className='text-[#393939] text-[14px] md:text-[16px] mt-[12px] md:mt-0'>{services.description}</p>
                     </div>
                     <div className=' bg-[#E6E6E6] h-[1px] mt-2 md:mt-0'></div>
                      <a href={`/services/${services.path}`}>
                               <button className="flex items-center gap-2 text-[#393939] mt-[20px] md:mt-[24px]">
                                 <div className='text-[14px] md:text-[16px] font-medium'>Daha çox</div>
                                 <img className="h-6 w-6" src={arrow} alt="Arrow Icon" />
                               </button>
                               </a>
                     </div>
                  ))}
    </div>
   </div>
  );
};
 
export default ServicesShort; 