import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { aboutMobile1, aboutMobile2, aboutMobile3, aboutMobile4, aboutMobile5, ellipse, phoneBg, phoneBg2, rightUp } from '../../../assets/index';


const About = () => {
 
  useEffect(() => {
    AOS.init({ duration: 1500 }); 
  }, []);   

  return ( 
   <div className="relative w-full flex items-center justify-center py-[80px] pb-[550px] md:pb-[80px]">
    <div className='flex flex-col-reverse md:flex-row max-w-containerSm md:max-w-container mx-auto'>
    <div className='md:w-[596px] relative mt-10 md:mt-0'>
      <div>
      <img className='absolute top-6 md:left-[237px] left-0 w-[300px]' src={aboutMobile1}></img>
      </div>
      <div>
      <img className='absolute top-0 left-[37px] md:left-[277px] w-[300px]' src={aboutMobile2}></img>
      </div>
      <div>
      <img className='absolute left-0 top-[100px] w-[380px]' src={aboutMobile4}></img>
      </div>
      <div >
      <img className='absolute md:left-10 top-[330px] md:w-[527px] w-[307px]' src={aboutMobile3}></img>
      </div>
      <div>
      <img className='absolute md:left-10 top-[330px] w-[307px] md:w-[527px]' src={aboutMobile5}></img>
      </div>
              <div className="absolute top-[120px] left-[140px] rotate-[-90deg] origin-left hidden md:block"> 
               <p className='text-[40px] font-bold'>+12K</p>  
               <p className='text-primeColor'>Our active member</p>
              
              </div>  
      </div>   
    <div className='md:w-[597px] flex justify-center items-center '>
      <div className='relative'>
      <p className='text-[14px] font-medium text-[#0092FF] mt-4'>Haqqımızda</p>
      <p className='mt-[15px] text-[20px] md:text-[24px] font-semibold relative z-10'>Biz, şirkətlərə mühəndislik və İT xidmətləri sahəsində genişmiqyaslı dəstək göstəririk.</p>
      <p className='text-[14px] mt-[15px] relative z-10'>TechWay – texnologiya dünyasında yeniliklərə açıq, müştərilərimizin ehtiyaclarına uyğun həllər təqdim edən bir şirkətdir. Bizim əsas məqsədimiz, biznesləri rəqəmsal dünyada inkişaf etdirmək, onlara texnologiya ilə əlaqəli xidmətlər təqdim edərək müvəffəqiyyət qazanmalarına dəstək olmaqdır..</p>
    <p className='mt-2 font-medium'>Göstərdiyimiz xidmətlər</p>
    <div className='flex gap-2 items-center mt-4'>
      <img className='h-2 w-2' src={ellipse}></img>
      <p className='text-[14px]'>Veb-sayt hazırlanması</p>
    </div> 
    <div className='flex gap-2 items-center mt-3'>
      <img className='h-2 w-2' src={ellipse}></img>
      <p className='text-[14px]'>Mobil Tətbiqlərin Hazırlanması</p>
    </div>
    <div className='flex gap-2 items-center mt-3'>
      <img className='h-2 w-2' src={ellipse}></img>
      <p className='text-[14px]'>Təlimçi Bazası</p>
    </div>
    <div className='flex gap-2 items-center mt-3'>
      <img className='h-2 w-2' src={ellipse}></img>
      <p className='text-[14px]'>SMM Xidmətlər</p>
    </div>
      </div>
    </div>
   </div>
   <div
        className="hidden md:block absolute top-0 right-0 h-full w-[280px] bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${phoneBg})` }}
      />
      <div
        className="block md:hidden absolute top-0 right-0 h-[460px] w-[270px] bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${phoneBg2})` }}
      />
      
   </div>
   
   
  );
}; 
 
export default About;      