import React from 'react';
import Carousel from '../../Components/home/Carousel/Carousel';
import Preferences from '../../Components/home/Preferences/Preferences';
import About from '../../Components/home/About/About';
import WhyUs from '../../Components/home/WhyUs/WhyUs';
import Contact from '../../Components/home/Contact/Contact';
import Team from '../../Components/home/Team/Team';
import Awards from '../../Components/home/Awards/Awards';
import ServicesShort from '../../Components/home/ServicesShort/ServicesShort';
import ProductShort from '../../Components/home/ProjectsShort/ProjectsShort';
import Hero from '../../Components/home/Hero/Hero';
import Kataloq from '../../Components/home/Kataloq/Kataloq'; 
import BlogsShort from '../../Components/home/Team/Team';
import ProjectsShort from '../../Components/home/ProjectsShort/ProjectsShort';
import Partners from '../../Components/home/Partners/Partners';
import Blog from '../../Components/home/Blog/Blog';
import Header from '../../Components/Header/Header';
import { heroBg } from '../../assets';



const Home = () => {
  return (
    <>
    <div className='bg-[#F3F4F5] pb-[80px]'>
    <div style={{
  backgroundImage: `url(${heroBg})`, 
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "880px",
  width: "100%",
}}>
      <Header/>
      <Hero/> 
    </div>
    <ServicesShort/>
    </div>
    <About/> 
    <ProjectsShort/>
    <Team/>
    <Blog/>
    <Partners/>
    </>
  )
}

export default Home;