import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { aboutBg, loading, partners1, projects1, underline } from '../../assets';
import Header from '../../Components/Header/Header';
import axios from 'axios';

const Team = () => {
 
  const [team, setTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initially set loading to true

const fetchTours = async () => {
  try {
    const response = await axios.get('https://www.techway.az/techway/team.php');
    setTeam(response.data.data);
    setIsLoading(false); // Set loading to false after data is fetched
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchTours();
}, []);


if (isLoading) {
 return (
   <div className="flex items-center justify-center h-screen">
     <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
   </div>
 );
}

  return (
    <>
    <Header/> 
     <div style={{height: window.innerWidth < 640 ? '174px' : '347px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
         
         <div className='text-center pt-10 h-[147px] md:h-[347px] flex flex-col justify-center items-center'>
           <p className='text-[20px] md:text-[40px] font-semibold text-white'>Komanda üzvləri</p>
           <div className='flex gap-2 justify-center items-center text-white mt-3'>
           <p className='text-[16px] text-primeColor'><a href='/'>Ana səhifə /</a></p>
           <p className='text-[16px] text-[#C1C1C1'><a href='/about'>Haqqımızda /</a></p>
           <p className='text-[16px] text-[#C1C1C1'>Komanda üzvləri</p>
           </div>
         </div>
         
       </div>
    <div className='max-w-containerSm md:max-w-container mx-auto flex justify-center items-center mt-[80px]'>
      <div className='w-full flex flex-col mx-auto justify-between'>
        <div className='text-[24px] md:text-[32px] text-center mx-auto font-medium md:font-semibold'> 
          <p className='text-[14px] text-primeColor'>Komanda </p>
          <p className='mt-[10px] text-[20px] md:text-[40px] font-semibold'>Komanda üzvləri </p>
          <img className='h-[3px] w-[270px] mt-[10px]  mb-[40px]' src={underline}></img>
          </div>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6'>
        {team.map((post) => (
          <a href={`/about/team/${post.id}`}>
            <div className='md:h-[370px] md:mb-[40px] w-full md:w-[275px] mx-auto'>
            <img className='h-[275px] w-full md:w-[275px]' src={`https://www.techway.az/techway/${post.image}`} alt="Partner 1" />
            <p className='md:text-[24px] font-medium mt-[10px]'>{post.category}</p>
            <p className='text-[14px]'>{post.title}</p>
          </div>
          </a>
        ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Team;