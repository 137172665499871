import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { loading, partners1, projects1, underline } from '../../../assets';
import axios from 'axios';

const Blogs = () => {

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initially set loading to true

const fetchTours = async () => {
  try {
    const response = await axios.get('https://www.techway.az/techway/blogs.php');
    setBlogs(response.data.data);
    setIsLoading(false); // Set loading to false after data is fetched
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchTours();
}, []);


if (isLoading) {
 return (
   <div className="flex items-center justify-center h-screen">
     <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
   </div>
 );
}

 
  return (
    <div className='md:max-w-container max-w-containerSm mx-auto flex justify-center items-center mt-[45px] md:mt-[80px]'>
      <div className='w-full flex flex-col mx-auto justify-between'>
        <div className='text-[24px] md:text-[32px] text-center mx-auto font-semibold'> 
          <p className='text-[14px] text-primeColor hidden md:block'>B L O Q </p>
          <p className='text-[14px] text-primeColor md:hidden'>Bloq </p>
          <p className='mt-[10px] text-[20px] md:text-[40px] font-semibold'>Ən son bloqlar </p>
          <img className='h-[3px] w-[270px] mt-[10px]  mb-[40px]' src={underline}></img>
          </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6'>
        {blogs.map((post) => (
          <a href={`/blogs/${post.id}`}>
            <div className='md:mb-[40px] md:w-[389px] rounded-[30px] border border-[#E8E9EB]'>
            <img className='h-[271px] md:w-[389px]' src={`https://www.techway.az/techway/${post.image}`} alt="Partner 1" />
           <div className='py-[20px] px-[30px]'>
            <button className='bg-primeColor rounded-[50px] text-[14px] md:text-[16px] text-white px-[13px]'>{post.category}</button>
            <p className='md:mt-[20px] mt-[12px] text-[16px] md:text-[20px] font-medium text-[#1D2C38]'>{post.title}</p>
           </div>
          </div>
          </a>
        ))}
        </div>
        <div>
        <p className='flex justify-center mt-[20px] md:mt-0'><a href='/blogs'><p className='text-[14px] text-primeColor font-medium'>Hamısını gör</p></a></p>
        </div> 
      </div>
    </div>
  );
};

export default Blogs;