import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import { about, aboutBg, hero, service2, startup } from "../../assets";

export default function Startup() {


  const { t, i18n } = useTranslation();
  return ( 
    <>
    <Header/>
    <div style={{height: window.innerWidth < 640 ? '174px' : '347px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
             
             <div className='text-center pt-10 h-[147px] md:h-[347px] flex flex-col justify-center items-center'>
               <p className='text-[20px] md:text-[40px] font-semibold text-white'>{t("Startupınızı başladın")}</p>
               <div className='flex gap-2 justify-center items-center text-white mt-3'>
               <p className='text-[16px] text-primeColor'><a href='/'>{t("Ana səhifə /")}</a></p>
               <p className='text-[16px] text-[#C1C1C1'>Startupınızı başladın</p>
               </div>
             </div>
             
           </div>
       <div className="max-w-containerSm md:max-w-container mx-auto mt-[36px] md:mt-[52px]">
   <div className="md:flex justify-between">
    <div className="md:w-[565px]">
    <p className="text-[20px] md:text-[30px] font-medium ">İdeyanızı reallığa çevirin. Bizə müraciət edin</p>
 <p className="text-black text-base font-normal mb-2 mt-10">Ad, soyad*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="Lorem ipsum"></input>
 <p className="text-black text-base font-normal mb-2">E-mail*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="email@gmail.com"></input>
 <p className="text-black text-base font-normal mb-2">Əlaqə nömrəsi*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2"></input>
 <p className="text-black text-base font-normal mb-2">Mesaj</p>
 <textarea className="bg-[#f4f4f4] rounded-xl h-[114px] w-full mb-7 p-2" placeholder="Bizə yazın..."></textarea>
 <button className="text-base font-normal w-[132px] h-[42px] rounded-xl border bg-[#2C5AFF] text-[white] hover:bg-[#898989]">GÖNDƏR</button>
    </div>
    <div className="md:w-[550px] mt-[20px] md:mt-0">
      <img className="w-full" src={startup}></img>
      </div>
   </div>
     </div>
     
    </>
  );
} 