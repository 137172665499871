import React, { useEffect, useState } from 'react';
import './partners.css';

import { partners1, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, partners10, loading } from '../../../assets/index';
import axios from 'axios';


const Partners = () => {

  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initially set loading to true

const fetchTours = async () => {
  try {
    const response = await axios.get('https://www.techway.az/techway/partners.php');
    setPartners(response.data.data);
    setIsLoading(false); // Set loading to false after data is fetched
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchTours();
}, []);


if (isLoading) {
 return (
   <div className="flex items-center justify-center h-screen">
     <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
   </div>
 );
}




  return (
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[0px] md:mt-[180px]'>
      <p className='md:text-[40px] text-[20px] font-semibold text-center mb-10'>Partnyorlar</p>
      
      {/* Scrollable Container */}
      <div className='overflow-x-auto scrollbar-hide'>
        <div className='flex space-x-8 md:justify-between min-w-max'>
          {partners.map((partner, index) => (
            <div key={index}>
              <img className='h-[65px] md:h-[80px]' src={`https://www.techway.az/techway/${partner.image}`} alt={`Partner ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;

