import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, aboutLeft1, aboutLeft2, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blueCircle, cardReader, carousel2, dataTerminal, hero, phoneBg, phoneBg2, pulQutulari, rightUp } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


const About = () => {
 
  const { t, i18n } = useTranslation();
 
  useEffect(() => {
    AOS.init({ duration: 1500 }); 
  }, []);   
  return (
   <div className="relative w-full min-h-screen flex items-center justify-center py-[80px]">
    <div className='flex flex-col-reverse md:flex-row max-w-containerSm md:max-w-container mx-auto'>
    <div className='md:w-[596px] relative '>
      <img className='relative z-20' src={about}></img>
      <div className="absolute top-[54px] left-[0px] z-10">
                <img className="h-[370px] w-[320px]" src={aboutLeft1} alt="Blue Circle" />
              </div>
      <div className="absolute md:top-[90px] top-[80px] left-[70px] md:left-[120px] z-30 ">
                <img className="md:h-[120px] h-[74px] w-[74px] md:w-[120px]" src={aboutLeft2} alt="Blue Circle" />
              </div> 
              <div className="absolute md:top-[120px] top-[100px] left-[220px] md:left-[310px] rotate-[-90deg] origin-left z-30 md:z-10"> 
               <p className='text-[40px] font-bold'>+12K</p>  
               <p className='text-primeColor'>Our active member</p>
              
              </div>  
      </div>   
    <div className='md:w-[638px] flex justify-center items-center'>
      <div className='relative'>
      <p className='text-[14px] font-medium text-[#0092FF]'>Haqqımızda</p>
      <p className='mt-[15px] text-[20px] md:text-[24px] font-semibold relative z-10'>Biz, şirkətlərə mühəndislik və İT xidmətləri sahəsində genişmiqyaslı dəstək göstəririk.</p>
      <p className='text-[14px] mt-[15px] relative z-10'>TechWay – texnologiya dünyasında yeniliklərə açıq, müştərilərimizin ehtiyaclarına uyğun həllər təqdim edən bir şirkətdir. Bizim əsas məqsədimiz, biznesləri rəqəmsal dünyada inkişaf etdirmək, onlara texnologiya ilə əlaqəli xidmətlər təqdim edərək müvəffəqiyyət qazanmalarına dəstək olmaqdır..</p>
    <a href="/about">
              <button className="flex w-[148px] h-[40px] rounded-[20px] px-4 gap-[10px] text-[14px] items-center text-white mt-[15px] bg-primeColor">
                <div>Daha ətraflı</div>
                <img className="h-6 w-6" src={rightUp} alt="Arrow Icon" />
              </button>
              </a>
      </div>
    </div>
   </div>
   <div
        className="hidden md:block absolute top-0 right-0 h-full w-[390px] bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${phoneBg})` }}
      />
      <div
        className="block md:hidden absolute top-0 right-0 h-[460px] w-[270px] bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${phoneBg2})` }}
      />
      
   </div>
   
   
  );
}; 
 
export default About;      