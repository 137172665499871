import React from 'react'
import { aboutBg, hero} from '../../assets/index';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';

const BlogDetails = () => {

  const { t, i18n } = useTranslation();
  return (
   <>
   <Header/> 
     <div style={{height: window.innerWidth < 640 ? '174px' : '347px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
         
         <div className='text-center pt-10 h-[147px] md:h-[347px] flex flex-col justify-center items-center'>
           <p className='text-[20px] md:text-[40px] font-semibold text-white'>Bloq</p>
           <div className='flex gap-2 justify-center items-center text-white mt-3'>
           <p className='text-[16px] text-primeColor'><a href='/'>Ana səhifə /</a></p>
           <p className='text-[16px] text-[#C1C1C1'>Bloq</p>
           </div>
         </div>
         
       </div>
  </>
  )
}

export default BlogDetails 