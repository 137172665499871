import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Contact from "./Pages/Contact/Contact";
import Partners from "./Pages/Partners/Partners";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Blogs from "./Pages/Blogs/Blogs.js";
import BlogDetail from "./Pages/BlogDetail/BlogDetail.js";
import Projects from "./Pages/Projects/Projects.js";
import SingleProject from "./Pages/SingleProject/SingleProject.js";
import ServicesVebsayt from "./Pages/AllServices/ServicesVebsayt/ServicesVebsayt.js";
import ServicesMobil from "./Pages/AllServices/ServicesMobil/ServicesMobil.js";
import ServicesTelimci from "./Pages/AllServices/ServicesTelimci/ServicesTelimci.js";
import ServicesSmm from "./Pages/AllServices/ServicesSmm/ServicesSmm.js";
import Team from "./Pages/Team/Team.js";
import SingleTeam from "./Pages/SingleTeam/SingleTeam.js";
import Startup from "./Pages/Startup/Startup.js";

const Layout = () => {
  return ( 
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/services/vebsayt" element={<ServicesVebsayt />}></Route>
        <Route path="/services/mobil" element={<ServicesMobil />}></Route>
        <Route path="/services/telimci" element={<ServicesTelimci />}></Route>
        <Route path="/services/smm" element={<ServicesSmm />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
        <Route path="/about/team" element={<Team />}></Route>
        <Route path="/about/team/:id" element={<SingleTeam />}></Route>
        <Route path="/projects/:id" element={<SingleProject />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/blogs/:id" element={<BlogDetail />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/startup" element={<Startup />}></Route>
        <Route path="/startup" element={<Contact />}></Route>
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/blog-detail/:id" element={<BlogDetail />} />
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="">
      <div className="font-bodyFont bg-[#FEFEFE]">
      <div>
    {/* <FloatingWhatsApp phoneNumber='+994552062366' accountName="INTEKO" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' /> */}
      <RouterProvider router={router} />
      </div>
    </div>
    </div>
  );
}

export default App;