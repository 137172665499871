import React, { useState} from "react";
import { hamburgerMenu, logo, messageBlue } from "../../assets/index";
import { FiX } from "react-icons/fi";
import { useLocation } from "react-router-dom";



const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  const location = useLocation(); 
   
 const isActiveLink = (path) => {
    if (path === "/") {
      return location.pathname === "/"; // Ana sayfa için tam eşleşme
    }
    return location.pathname.startsWith(path); // Diğer yollar için başlangıç eşleşmesi
  };
 
  
 
  return ( 
    <>
     <div className="h-[64px] max-w-containerSm md:max-w-container mx-auto pt-[12px]">
      <div className="h-[40px] flex justify-between">
      <div>
     <img className="w-[164px] h-[40px]" src={logo}></img>
      </div>
      <div className="md:flex gap-[19px] items-center hidden ">
        <a className={`${
          isActiveLink('/') ? "text-primeColor" : "text-black"
          }`} href="/">Əsas səhifə</a>
        <a className={`${
          isActiveLink('/services') ? "text-primeColor" : "text-black"
          }`} href="/services">Xidmətlər</a>
        <a className={`${
          isActiveLink('/about') ? "text-primeColor" : "text-black"
          }`} href="/about">Haqqımızda</a>
        <a className={`${
          isActiveLink('/projects') ? "text-primeColor" : "text-black"
          }`} href="/projects">Layihələr</a>
        <a className={`${
          isActiveLink('/blogs') ? "text-primeColor" : "text-black"
          }`} href="/blogs">Bloq</a>
        <a className={`${
          isActiveLink('/contact') ? "text-primeColor" : "text-black"
          }`} href="/contact">Əlaqə</a>
      </div>
      <a className="hidden md:block" href="/startup">
      <button className="flex w-[208px] h-[40px] rounded-[20px] px-4 gap-[10px] text-[14px] items-center border-[1px] border-[#2C5AFF]">
        <img className="h-6 w-6" src={messageBlue}></img>
        <div>Startupınızı başladın</div>
      </button>
      </a>
      <div onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="md:hidden flex justify-center items-center"><img className="h-6 w-6" src={hamburgerMenu}></img></div>
      </div>
      <div
      className={`fixed inset-0 bg-white text-black z-50 transform ${
        isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div className="max-w-containerSm mx-auto pt-8 flex flex-col h-full">
        <div className="flex justify-between">
          <img className="h-[49px]" src={logo} alt="Logo" />
          <button onClick={() => setIsMobileMenuOpen(false)}>
            <FiX size={32} />
          </button>
        </div>

        <nav className="mt-10">
          {[
            { path: "/", label: "Ana səhifə" },
            { 
              path: "/services", 
              label: "Xidmətlər", 
              submenu: [
                { path: "/services/vebsayt", label: "Veb-sayt hazırlanması" },
                { path: "/services/mobil", label: "Mobil Tətbiqlərin Hazırlanması" },
                { path: "/services/telimci", label: "Təlimçi Bazası" },
                { path: "/services/smm", label: "SMM Xidmətlər" },
              ]
            },
            { path: "/projects", label: "Layihələr" },
            { 
              path: "/about", 
              label: "Haqqımızda", 
              submenu: [
                { path: "/about/team", label: "Komanda üzvləri" },
              ] 
            },
            { path: "/blogs", label: "Bloq" },
            { path: "/contact", label: "Əlaqə" },
          ].map((item, index) => (
            <div key={index}>
              <a
                href={item.path}
                className="block py-2 rounded-lg transition"
              >
                {item.label}
              </a>

              {item.submenu && (
                <div className="ml-4">
                  {item.submenu.map((subItem, subIndex) => (
                    <a
                      key={subIndex}
                      href={subItem.path}
                      className="block text-[14px] py-2 pl-4 rounded-lg text-gray-600 transition hover:text-black"
                    >
                      {subItem.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>

        <a href="/startup">
          <button className="mt-5 text-primeColor text-[20px]">
            <div>Startupınızı başladın</div>
          </button>
        </a>
      </div>
    </div>
      </div>
    </>
  );  
};

export default Header; 
