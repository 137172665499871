import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, aboutBg, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, carousel2, dataTerminal, ellipse, hero, pulQutulari, rightUp } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import Partners from './Partners/Partners'
import Header from '../../Components/Header/Header';
import Team from './Team/Team'
import AboutSection from './About/About';


const About = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 }); 
  }, []);
  return (
  <>
   
   <Header/>
   <div style={{height: window.innerWidth < 640 ? '174px' : '347px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
     
     <div className='text-center pt-10 h-[147px] md:h-[347px] flex flex-col justify-center items-center'>
       <p className='text-[20px] md:text-[40px] font-semibold text-white'>{t("Haqqımızda")}</p>
       <div className='flex gap-2 justify-center items-center text-white mt-3'>
       <p className='text-[16px] text-primeColor'><a href='/'>{t("Ana səhifə /")}</a></p>
       <p className='text-[16px] text-[#C1C1C1'>{t("Haqqımızda")}</p>
       </div>
     </div>
     
   </div>
   <AboutSection/> 
   <Partners/>
   <Team/>
   </>
  ); 
}; 
 
export default About; 