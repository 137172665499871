import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { aboutBg, arrow, loading, partners1, projects1, service1, service2, service3, service4, underline } from '../../assets';
import Header from '../../Components/Header/Header';
import axios from 'axios';

const Projects = () => {


   const [blogs, setBlogs] = useState([]);
   const [isLoading, setIsLoading] = useState(true); // Initially set loading to true

 const fetchTours = async () => {
   try {
     const response = await axios.get('https://www.techway.az/techway/blogs.php');
     setBlogs(response.data.data);
     setIsLoading(false); // Set loading to false after data is fetched
   } catch (error) {
     console.error("Error fetching data:", error);
   }
 };

 useEffect(() => {
   fetchTours();
 }, []);

 
 if (isLoading) {
  return (
    <div className="flex items-center justify-center h-screen">
      <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
    </div>
  );
}

  return (
    <>
    <Header/> 
     <div style={{height: window.innerWidth < 640 ? '174px' : '347px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
         
         <div className='text-center pt-10 h-[147px] md:h-[347px] flex flex-col justify-center items-center'>
           <p className='text-[20px] md:text-[40px] font-semibold text-white'>Bloq</p>
           <div className='flex gap-2 justify-center items-center text-white mt-3'>
           <p className='text-[16px] text-primeColor'><a href='/'>Ana səhifə /</a></p>
           <p className='text-[16px] text-[#C1C1C1'>Bloq</p>
           </div>
         </div>
         
       </div>
    <div className='max-w-containerSm md:max-w-container mx-auto md:mt-[80px] mt-[48px]'>
       <div className='md:mt-[44px] mt-[36px] grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8'>
      {blogs.map((post) => (
                       <a href={`/blogs/${post.id}`}>
                         <div className='md:mb-[40px] md:w-[389px] rounded-[30px] border border-[#E8E9EB]'>
                        <img className='h-[271px] w-full md:w-[389px]' src={`https://www.techway.az/techway/${post.image}`} alt="Partner 1" />
                       <div className='py-[20px] px-[30px]'>
                        <button className='bg-primeColor rounded-[50px] text-[14px] md:text-[16px] text-white px-[13px]'>{post.category}</button>
                        <p className='md:mt-[20px] mt-[12px] text-[16px] md:text-[20px] font-medium text-[#1D2C38]'>{post.title}</p>
                       </div>
                      </div>
                       </a>
                     ))}
       </div>
      </div>
    </>
  );
};

export default Projects;