import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import { aboutBg, contact1, contact2, contact3, hero, heroBg, service2 } from "../../assets";
import axios from "axios";

export default function Contact() {


  const [settings, setSettings] = useState([]);

const fetchTours = async () => {
  try {
    const response = await axios.get('https://www.techway.az/techway/settings.php');
    setSettings(response.data[0]);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchTours();
}, []);

  return ( 
    <>
    <Header/>
    <div style={{height: window.innerWidth < 640 ? '174px' : '347px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
             
             <div className='text-center pt-10 h-[147px] md:h-[347px] flex flex-col justify-center items-center'>
               <p className='text-[20px] md:text-[40px] font-semibold text-white'>Əlaqə</p>
               <div className='flex gap-2 justify-center items-center text-white mt-3'>
               <p className='text-[16px] text-primeColor'><a href='/'>Ana səhifə /</a></p>
               <p className='text-[16px] text-[#C1C1C1'>Əlaqə</p>
               </div>
             </div>
             
           </div>
           <div className="md:max-w-container max-w-containerSm flex flex-col md:flex-row gap-4 md:gap-5 mx-auto mt-[36px] md:mt-[52px]">
    <div className="rounded-[25px] border-[1px] border-[#E5E5E5] py-[32px] md:py-[44px] md:px-[106px] text-center">
      <img className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] mx-auto" src={contact1}></img>
      <p className="mt-[25px] text-[16px] md:text-[20px] font-medium">+55 (9900) 666 22</p>
      <p className="font-medium text-[14px] md:text-[16px]">Əlaqə nömrəsi</p>
    </div>
    <div className="rounded-[25px] border-[1px] border-[#E5E5E5] py-[32px] md:py-[44px] md:px-[106px] text-center">
      <img className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] mx-auto" src={contact2}></img>
      <p className="mt-[25px] text-[16px] md:text-[20px] font-medium"> info@techway.az</p>
      <p className="font-medium text-[14px] md:text-[16px]">Elektron poçt</p>
    </div>
    <div className="rounded-[25px] border-[1px] border-[#E5E5E5] py-[32px] md:py-[44px] md:px-[106px] text-center">
      <img className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] mx-auto" src={contact3}></img>
      <p className="mt-[25px] text-[16px] md:text-[20px] font-medium">14/4, Bonosri, USA.</p>
      <p className="font-medium text-[14px] md:text-[16px]">Ünvan</p>
    </div>
           </div>
       <div className="max-w-containerSm md:max-w-container mx-auto mt-[48px] md:mt-[60px]">
    <p className="text-black text-[20px] md:text-[30px] font-medium text-center">Layihən var? Bizimlə əlaqə saxla</p>
    <div className="md:w-[643px] mx-auto mt-[36px]">
 <p className="text-black text-base font-normal mb-2">Ad, soyad*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="Lorem ipsum"></input>
 <p className="text-black text-base font-normal mb-2">E-mail*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="email@gmail.com"></input>
 <p className="text-black text-base font-normal mb-2">Əlaqə nömrəsi*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2"></input>
 <p className="text-black text-base font-normal mb-2">Mesaj</p>
 <textarea className="bg-[#f4f4f4] rounded-xl h-[114px] w-full mb-7 p-2" placeholder="Bizə yazın..."></textarea>
 <button className="text-base font-normal w-[132px] h-[42px] rounded-xl border bg-[#2C5AFF] text-[white] hover:bg-[#898989]">GÖNDƏR</button>
    </div>
     </div>
     
    </>
  );
}