import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { aboutBg, arrow, partners1, projects1, service1, service2, service3, service4, underline } from '../../assets';
import Header from '../../Components/Header/Header';

const Services = () => {

  const services = [
    { image: service1, title: "Veb-sayt hazırlanması", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'vebsayt' },
    { image: service2, title: "Mobil tətbiqlərin hazırlanması", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'mobil' },
    { image: service3, title: "Təlimçi bazası", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'telimci' },
    { image: service4, title: "SMM Xidmətləri", description: 'Phasellus neque nibh, cursus ullamcorper at.', path: 'smm' },
  ];


 
  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/> 
     <div style={{height: window.innerWidth < 640 ? '174px' : '347px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
         
         <div className='text-center pt-10 h-[147px] md:h-[347px] flex flex-col justify-center items-center'>
           <p className='text-[20px] md:text-[40px] font-semibold text-white'>{t("Xidmətlər")}</p>
           <div className='flex gap-2 justify-center items-center text-white mt-3'>
           <p className='text-[16px] text-primeColor'><a href='/'>{t("Ana səhifə /")}</a></p>
           <p className='text-[16px] text-[#C1C1C1'>Xidmətlər</p>
           </div>
         </div>
         
       </div>
    <div className='max-w-containerSm md:max-w-container mx-auto md:mt-[80px] mt-[48px]'>
       <div className='md:mt-[44px] mt-[36px] grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8'>
      {services.map((services) => (
                        <div className='px-5 py-5 md:py-6 rounded-[20px] bg-white border-[1px] border-[#F3F4F5]'>
                        <img className='md:h-[64px] h-[36px] md:w-[64px] h-[36px]' src={services.image}></img>
                        <div className='md:h-[124px] flex flex-col justify-between mt-[20px] md:mt-[32px]'>
                        <p className='text-[16px] md:text-[20px] font-medium'>{services.title}</p>
                        <p className='text-[#393939] text-[14px] md:text-[16px] mt-[12px] md:mt-0'>{services.description}</p>
                        </div>
                        <div className=' bg-[#E6E6E6] h-[1px] mt-2 md:mt-0'></div>
                         <a href={`/services/${services.path}`}>
                                  <button className="flex items-center gap-2 text-[#393939] mt-[20px] md:mt-[24px]">
                                    <div className='text-[14px] md:text-[16px] font-medium'>Daha çox</div>
                                    <img className="h-6 w-6" src={arrow} alt="Arrow Icon" />
                                  </button>
                                  </a>
                        </div>
                     ))}
       </div>
      </div>
    </>
  );
};

export default Services;