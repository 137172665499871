import React, { useEffect, useState } from "react";
import { blueCircle, hero, rightUp, videoPlay } from "../../../assets/index";
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="max-w-containerSm md:max-w-container mx-auto md:mt-[96px] mt-[60px] md:flex md:gap-[25px] relative">
      <div className="flex justify-center items-center md:w-[693px] relative">
        <div>
          <p className="text-[20px] md:text-[40px] font-bold">
            Texnologiyanın Gücü İlə Gələcəyə Bir Addım Daha Yaxın Olun
          </p>
          <p className="text-[14px] md:text-[18px] mt-[16px] md:mt-[24px]">
            Biznesinizi rəqəmsal aləmdə inkişaf etdirərək TechWay-in qabaqcıl texnologiya həlləri ilə uğura çatdırın.
          </p>
          <a href="/about">
          <button className="flex w-[148px] h-[40px] rounded-[20px] px-4 gap-[10px] text-[14px] items-center text-white border-[1px] mt-[16px] md:mt-[24px] bg-primeColor">
            <div>Daha ətraflı</div>
            <img className="h-6 w-6" src={rightUp} alt="Arrow Icon" />
          </button>
          </a>
        </div>
        <div className="absolute top-0 left-8 hidden md:block">
          <img className="h-4 w-4" src={blueCircle} alt="Blue Circle" />
        </div> 
      </div>

      <div className="md:w-[473px] relative mt-[40px] md:mt-0">
        <img
          className="md:w-[130px] md:h-[130px] w-[83px] h-[77px] absolute top-[160px] md:top-[182px] left-[88px] md:left-[118px] cursor-pointer"
          src={videoPlay}
          alt="Play Video" 
          onClick={() => setIsOpen(true)}
        />
        <img className="w-full" src={hero} alt="Hero Image" />
      </div>

      {/* Video Modal */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="relative w-[900px] h-[500px] bg-white flex justify-center items-center rounded-lg">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              title="YouTube Video"
              allowFullScreen
              className="w-full h-full rounded-lg"
            ></iframe>
            <button
              className="absolute top-2 right-2 bg-primeColor text-white w-8 h-8 flex items-center justify-center rounded-full"
              onClick={() => setIsOpen(false)}
            >
              ✖
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
