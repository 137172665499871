import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { loading, partners1, projects1, underline } from '../../../assets';
import axios from 'axios';

const Projects = () => {

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initially set loading to true

const fetchTours = async () => {
  try {
    const response = await axios.get('https://www.techway.az/techway/projects.php');
    setProjects(response.data.data);
    setIsLoading(false); // Set loading to false after data is fetched
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchTours();
}, []);


if (isLoading) {
 return (
   <div className="flex items-center justify-center h-screen">
     <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
   </div>
 );
}


  return (
    <div className='md:max-w-full flex justify-center items-center mt-[0px] md:mt-[80px] max-w-containerSm mx-auto'>
      <div className='w-full flex flex-col mx-auto justify-between'>
        <div className='text-[24px] md:text-[32px] text-center mx-auto font-normal md:font-semibold'>
          <p className='text-[14px] text-primeColor'>Layihələr</p>
          <p className='mt-[10px] text-[20px] md:text-[40px] font-semibold'>Layihələr</p>
          <img className='h-[3px] w-[270px] mt-[10px]' src={underline}></img>
          </div>
        <div> 
          <Swiper
            spaceBetween={30}  // Görseller arasındaki boşluk 
            loop={true}        // Slider sonsuz döngüde olsun
            pagination={{ clickable: true }}  // Sayfalandırma noktalarını etkinleştir, tıklanabilir yap
            modules={[Pagination]}  // Pagination modülünü yükle
            breakpoints={{
              640: {  // Ekran genişliği 640px ve üzeri olduğunda
                slidesPerView: 2,  // 2 görsel gösterilsin
              },
              768: {  // Ekran genişliği 768px ve üzeri olduğunda
                slidesPerView: 3,  // 3 görsel gösterilsin
              },
              1024: {  // Ekran genişliği 1024px ve üzeri olduğunda
                slidesPerView: 4,  // 4 görsel gösterilsin
              },
              480: {  // Ekran genişliği 480px ve altı olduğunda
                slidesPerView: 1,  // Sadece 1 görsel gösterilsin
              },
            }}   // Pagination modülünü yükle
          >
            {/* Tüm partner görselleri için SwiperSlide bileşeni */}
            {projects.map((post) => (
          <SwiperSlide>
          <div className='md:h-[545px] h-[450px] flex justify-center items-center'>
            <img className='md:h-[445px] w-full h-[360px]' src={`https://www.techway.az/techway/${post.image}`} alt="Partner 1" />
          </div>
        </SwiperSlide>
        ))}
          
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Projects;